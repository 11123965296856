@charset "UTF-8";
@import 'variable';
@import './mixins';

.btn:focus,
.btn.focus,
.btn:active,
.form-control:focus {
  box-shadow: none !important;
}

.btn-primary-control {
  color: #ffffff;
  background-color: $theme-blue-dark !important;
}


.btn-primary-control:focus,
.btn-primary-control:active,
.btn-primary-control:hover {
  background-color: $theme-blue-dark !important;
  color: $whiteBg !important;
  @include font(16, 600);
}

.btn-primary-main {
  color: $textPrimary;
  @include font(16, 600);
  background-color: initial;
  border: 1px solid $textPrimary;
}


.btn-primary-main:focus,
.btn-primary-main:active,
.btn-primary-main:hover {
  @include font(16, 600);
  background-color: $textPrimaryLight !important;
  color: $whiteBg !important;
}

.btn-primary-main {
  background: $textPrimary !important;
  border: 1px solid $textPrimary !important;
  color: $whiteBg !important;
  @include font(16, 600);
}


.btn-primary {
  background: $textPrimary !important;
  border: 1px solid $textPrimary !important;
  @include font(16, 600);
}

a.text-primary,
.text-primary {
  color: $textPrimary !important;
}

// common auth buttons 
.btn-primary-border:focus,
.btn-primary-border:active,
.btn-primary-border:hover {
  background-color: $textPrimaryLight !important;
  color: $whiteBg !important;
}

.btn-primary-border {
  margin-right: 10px;
  background: initial;
  border: 1px solid $textPrimary !important;
}


.btn-auth-primary {
  background: $textPrimary !important;
  border: 1px solid $textPrimary !important;
  @include font(18, 600);
  border-radius: 0px !important;
  padding: 10px !important;
}

.social-btn-group {
  display: flex;
  justify-content: space-between;

  .social-btn {
    border: 2px solid $brand-dark-gray !important;
    @include font(18, 600);
    border-radius: 0px;
    padding: 5px;
    width: 31%;
    justify-content: center;
    margin-bottom: .5rem;
    padding: .5rem 1rem;
    text-align: center;
    transition: box-shadow .3s;
  }
}

.br-0 {
  border-radius: 0px !important;
}