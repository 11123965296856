@import '../../assets/scss/placeholder';
@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';

.page-wrapper {
  background: url('https://i.udemycdn.com/notices/home_banner/image/e10582cf-c6d2-4697-a2a2-c69607570bf4.jpg') no-repeat center center;
  background-size: cover;
  min-height: 500px;
  background-size: cover;
  background-position: center;

  .home-wrapper {
    background-color: rgba(0, 0, 0, 0.48);
    color: #fff;
    min-height: 500px;

    .container {
      .fullscreen-video-container {
        left: 0;
        overflow: hidden;
        position: absolute;
        top: 70px;
        width: 100%;
      }

      .header__background {
        opacity: 0.89;
        left: 0;
        position: absolute;
        top: 70px;
        width: 100%;
      }

      .header__content {
        max-width: 100%;
        z-index: 2;

        .home--hero-header {
          padding-top: 50px;
          width: 95%;
          margin: 0 auto;

          @include media(null, $bp-medium) {
            padding-top: 20px;
          }

          .formContainer {
            margin: 30px 0 20px 0;
            transition: top 0.5s ease;
            top: 0px;

            .formInputBtn {
              background-repeat: no-repeat;
              background-position: 90% center;
              background-size: 20px;
              padding-right: 50px;
            }

            .formInputBtn {
              border-top-right-radius: 6px;
              background-color: $textPrimary;
              border: none;
              padding: 15px;
              line-height: 18px;
              @extend %h5;
              color: #ffffff;
              border-bottom-right-radius: 6px;
              margin: 0px;
            }

            .formSearchInput {
              background-image: url(/assets/new-ui/search_grey_header.png);
              background-repeat: no-repeat;
              background-position: 15px center;
              background-size: 20px;
            }

            .formSearchInput {
              border-top-left-radius: 6px;
              background-color: #ffffff;
              border-bottom-left-radius: 6px;
              padding: 10px;
              @extend %h4;
              line-height: 26px;
              color: #323639;
              padding-left: 20px;
              width: 70%;
              border: none;
              margin: 0px;

              &::placeholder {
                @extend %h6;
              }

              @include media(null, $bp-xmedium) {
                width: 60%;
              }
            }
          }
        }
      }

      .header__content,
      .mb-4 {
        transform: translateY(0px);
      }

      .center-logo {
        display: block;
        text-align: center;
        margin-bottom: 25px;
      }

      .hero-header-title {
        @extend %h1;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
      }

      .hero-header-subtitle {
        @extend %h3;
        font-weight: 500;
        line-height: 1.2;
        text-align: center;
      }
    }
  }
}