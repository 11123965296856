@import '../../assets/scss/variable';
@import '../../assets/scss/mixins';

html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
}

.br-0 {
  border-radius: 0px;
}

.body-container {
  background: #ffff;
  background: #ffff;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-size: 0.95rem;

  .collageContainer {
    @include media (null, $bp-medium) {
      display: none;
    }

    position: relative;
    min-height: 44.6875rem;
    margin-right: 12%;

    .learner {
      position: absolute;
      right: 0;
      z-index: 1;
      width: 90%;
      top: 8%;
      max-width: 36rem;
    }

    .bars {
      position: absolute;
      top: 15%;
      left: 0;
      max-width: 33.3%;
      z-index: 2;
    }

    .triangle {
      @include media ($bp-medium, $bp-xmedium) {
        display: none;
      }

      position: absolute;
      left: -31px;
      z-index: 1;
      bottom: 0px;
      width: 50%;
    }

    .rectangle {
      left: 50%;
      left: 50%;
      position: absolute;
      z-index: 0;
      max-width: 50%;
    }

    .titleContainer {
      background-color: #6400e4;
      padding: 2rem;
      position: absolute;
      left: -2rem;
      top: 40%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;

      @include media ($bp-medium, $bp-xmedium) {
        top: 42%;
        padding: 1.5rem;
      }

      h1 {
        @include media ($bp-medium, $bp-xmedium) {
          font-size: 1.5rem;
        }

        font-size: 1.5rem;
        color: $whiteBg;
        margin-bottom: 0;

      }
    }
  }

  .form-container {
    padding: 4rem 2rem 2rem;
    width: auto;
  }
}

.scrolled {
  -webkit-transition: padding ease .2s;
  -o-transition: padding ease .2s;
  transition: padding ease .2s;
  -webkit-box-shadow: 0px 1px 3px rgba(76, 76, 76, 0.2);
  box-shadow: 0px 1px 3px rgba(76, 76, 76, 0.2);
  padding: 8px 0 !important;
}

footer {
  @include media (null, $bp-medium) {
    margin-top: 25px;
    position: static;
  }

  bottom: 0;
  left: 0;
  margin-top: 100px;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.footer-wrapper {
  background-color: #A6CE42;
  color: #0F69AF;
  font-family: Verdana, sans-serif;
  padding: 20px 0;

  h5 {
    margin: 0;
    margin-bottom: .6em;
    line-height: 20px;
    letter-spacing: .5px;
    font-weight: bold;
    font-size: 11px;
  }

  h6 {
    margin: 0;
    line-height: 15px;
    letter-spacing: .5px;
    font-weight: bold;
    font-size: 10px;
  }
}

.cookieConsent {
  background: #333;
  text-align: center;
  font-size: 12px;

  button {
    border: solid 2px #FFCB05;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;
    border-radius: 6px !important;
    background-color: #FFCB05 !important;
    color: #16BECF !important;
    font-weight: bold;

    &:hover {
      border-color: #16BECF;
      -webkit-transition: ease .1s all;
      -o-transition: ease .1s all;
      transition: ease .1s all;
    }
  }
}

.body-container {
  .banner {
    background-color: #fff;
    box-shadow: 0 3px 6px -3px #bdbdbd;
    padding: 40px 0 48px;

    .site-width {
      margin: 0 auto;
      max-width: 1140px;
      width: 90vw;

      .banner-title {
        margin-bottom: 16px;
      }

      .banner-info {
        -ms-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        line-height: 32px;
        margin-right: 32px;
      }
    }
  }
}

.featuresGrid {
  margin-top: 30px;
  margin: 10px auto;
  max-width: 1140px;
  width: 90vw;

  .feature {
    min-width: 100%;
    padding: 20px;
    text-align: left;
    background: #c8e5f2;
    padding: 10px;
    box-shadow: 0 3px 6px -3px #bdbdbd;
    margin: 10px;

    &__icon {
      height: 2rem;
      margin-bottom: 1rem;
      width: 2.375rem;
    }

    &__content {
      &__heading {
        font-family: Roboto, sans-serif;
        font-size: 1.25rem;
        margin-bottom: .5rem;
      }

      &__description {
        max-width: inherit;
      }
    }
  }
}