@import '../../../assets/scss/variable';
@import '../../../assets/scss/mixins';
@import '../../../assets/scss/placeholder';

.auth-wrapper {
  background: $brand-white !important;
  display: flex;
  flex-direction: column;
  width: 100%;


  .image-placeholder {
    background: none;
    color: $brand-white;
    border: 1px solid $brand-white !important;
    min-height: 400px;
    padding: 175px 0px;
  }

  .message-error {
    color: $errorMsg;
  }

  header {
    nav.navbar {
      background: #0f69af;
    }
  }

  section {
    .auth-left-wrapper {
      h2 {
        color: #0f69af;
        font-family: 'Lato';
        font-size: 28px;
        line-height: 1.2em;
        letter-spacing: 0.5px;
        font-weight: bold;
      }

      p {
        font-family: 'Lato';
        font-size: 14px;
        color: #4d4d4d;
        font-weight: normal;
      }
    }

    a:focus,
    a:active,
    a:focus {
      outline: none;
      color: $brand-purple !important;
    }
  }

  footer {
    position: relative;
    margin-top: -150px;

    img.left-bottom {
      position: absolute;
      bottom: 0;
    }
  }

  .auth-card {
    font-size: 14px;
    position: relative;
    z-index: 2;

    h2 {
      color: $brand-purple;
      font-size: 38px;

      a.text-muted {
        font-size: 20px;
      }
    }

    .social-option {
      span {
        @extend %h4;
      }
    }

    .form-group {
      .input-control {
        font-size: 1rem;
        height: 50px;
        text-indent: .8rem;
        outline: none;
        width: 100%;
        border: 1px solid #e9eaea;
        border-radius: 2px;
        transition: border-color .15s;
        font-weight: 400;
        box-sizing: border-box;

        &:focus {
          border-color: $inputBorder;
        }
      }

      label {
        color: $body-color;
        font-size: 15px;
      }

      .border-white,
      .border-0 {
        font-size: 13px;
        color: $brand-black;
        border-radius: $border-radius;
      }

      a {
        color: $brand-purple;
        font-size: 15px;
      }

      .btn-warning {
        background: #ffcb06;
        border-color: #ffcb06;
        color: #0165b3;
        border-radius: $border-radius;
      }

      .custom-select {
        background: url('../../../assets/img/arrow.png') no-repeat right;
        background-size: 15px;
        background-position: 98%;

        &:active,
        &:focus {
          outline: none !important;
        }
      }
    }

    .custom-check {
      label {
        cursor: pointer;

        input {
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
          position: absolute;
          left: 15px;
          height: 20px;
          width: 20px;
          background-color: #eee;
          border-radius: 5px;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
          content: '';
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        input:checked~.checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        .checkmark:after {
          left: 8px;
          top: 4px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 2px 2px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

        /* When the checkbox is checked, add a blue background */
        input:checked~.checkmark {
          background-color: $brand-primary;
          border-radius: 5px;
        }
      }

      label.terms {
        display: flex;
      }
    }

    .error-msg {
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545;
    }

    footer {
      .text-info {
        color: #0165b3 !important;
        font-size: 16px;
      }
    }
  }
}