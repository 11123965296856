* {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  box-sizing: border-box;
  font-size: 14px;
}

img {
  max-width: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  width: 100% !important;
  height: 100%;
  line-height: 1.6em;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

a {
  color: #1c1c1c;
  cursor: pointer;
  text-decoration: none;
}

.body-container {
  flex-grow: 1;

  &.page-body {
    padding-bottom: 40px;
    padding-top: 40px;
  }
}

.padding-lr {
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 1024px) {
  .container.alt {
    max-width: 1200px;
  }
}