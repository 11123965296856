@import '../../assets/scss/mixins';
@import '../../assets/scss/variable';
@import '../../assets/scss/placeholder';


.nav-icon {
  height: 100px;
  width: 100px;
}
.hero-selector {
  margin-top: 50px;
  text-align: center;
  &__actions {
    justify-content: center;
    display: flex;
    &--grid {
      margin: 20px;
      button {
        width: 200px;
        border: none;
      }
    }
  }

}

.nav-icon-original {
  height: 100px;
  width: 100px;
}
.grid-col {
  padding: 30px;
  flex-wrap: wrap;
  display: flex;
  justify-content: center;

  .btn-next-step {
    padding: 20px;
    margin: 15px;
    /* display: block; */
    border: 1px solid #ccc;
    flex-basis: 20%;
  }
}

