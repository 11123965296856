@import '../../../../assets/scss/variable';
@import '../../../../assets/scss/placeholder';



.rightMenu {

  &-search {
    flex-basis: 50%;
    margin-right: 10px;

    &-box {
      background-size: 12px;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #ffffff;
      padding: 4px 10px 4px 28px;
      outline: none;
      height: 28px;
      box-sizing: border-box;
      font-size: 14px;
      background-size: 20px;
      -webkit-transition: width 0.8s ease-in-out;
      transition: width 0.8s ease-in-out;

    }
  }

  &-section {
    @include media (null, $bp-medium) {
      float: none;
      margin: 24px 0 23px;
      position: absolute;
      right: 20px;
      top: 0;
    }

    float: right;
  }

  &_list {
    @include padding-margin;
    @include clear-list;
    float: right;
  }

  &_item {
    @include media (null, $bp-xmedium) {
      padding: 0 16px;

      &:nth-last-child(4) {
        padding-left: 0;
      }
    }

    @include media (null, $bp-medium) {
      padding: 0 10px;
    }

    display: inline-block;
    padding: 0 15px;
    position: relative;
    vertical-align: middle;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }

    span {
      cursor: pointer;
    }

    &.item-hide {
      @include media (null, $bp-large) {
        display: none;
      }
    }
  }

  &_img {
    height: 22px;
    position: relative;
    width: 22px;
    z-index: 2;
  }

  &_notification {
    &-count {
      @include media (null, $bp-xmedium) {
        left: 24px;
      }

      @include media (null, $bp-medium) {
        left: 12px;
      }

      background-color: $red-color;
      border-radius: 100%;
      color: $white-color;
      left: 50px;
      min-width: 22px;
      padding: 1px 3px 0;
      position: absolute;
      text-align: center;
      top: -10px;
    }
  }
}