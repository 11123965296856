@import 'mixins';
@import 'variable';

html {
  @include font($base-size, normal);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%;
  line-height: 20px;
}

body {
  @include font(12, 400);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: $black-color;
  font-family: $font-roboto;
  height: 100%;
  line-height: 20px;
  margin: 0;
}

*,
*::after,
*::before {
  @include box-sizing(border-box);
  outline: none;
  word-break: break-word;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  word-break: break-word;
}

h1 {
  @include media(null, $bp-medium) {
    @include font(24, 400);
    line-height: 32px;
  }
  @include font(32, 400);
  font-family: $font-roboto;
  line-height: 40px;
}

h2 {
  @include media(null, $bp-medium) {
    @include font(20, 400);
    line-height: 32px;
  }
  @include font(24, 400);
  font-family: $font-roboto;
  line-height: 32px;
}

h3 {
  @include media(null, $bp-medium) {
    @include font(16, 400);
    line-height: 24px;
  }
  @include font(20, 400);
  font-family: $font-roboto;
  line-height: 32px;
}

h4 {
  @include media(null, $bp-medium) {
    @include font(10, 400);
    font-family: $font-roboto;
    line-height: 16px;
  }
  @include font(16, 400);
  font-family: $font-roboto;
  line-height: 24px;
}

h5 {
  @include font(10, 600);
  font-family: $font-roboto;
  line-height: 16px;
  text-transform: uppercase;
}

h6 {
  @include font(10, 600);
  font-family: $font-roboto;
  line-height: 16px;
  text-transform: uppercase;
}

a {
  color: $black-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}
