@mixin media($min: null, $max: null) {
  @if ($min) {
    @if ($max) {
      @media only screen and (min-width: #{$min}) and (max-width: #{$max}) {
        @content;
      }
    }

    @else {
      @media only screen and (min-width: #{$min}) {
        @content;
      }
    }
  }

  @else {
    @if ($max) {
      @media only screen and (max-width: #{$max}) {
        @content;
      }
    }
  }
}

@mixin font($pixels, $weight: normal) {
  // font-size: $pixels + px;
  font-size: ($pixels / $base-font-size) + rem;
  font-weight: $weight;
}

@mixin box-sizing($sizing) {
  -webkit-box-sizing: $sizing;
  -moz-box-sizing: $sizing;
  box-sizing: $sizing;
}

// Apply to UL
@mixin padding-margin {
  margin: 0;
  padding: 0;
}

// Apply to UL
@mixin clear-list {
  list-style: none;
}


@mixin ul-li-clear-list {
  ul {
    @include padding-margin;

    li {
      @include clear-list;
    }
  }
}

// clearfix
@mixin clear-box {
  &::after {
    clear: both;
    content: '';
    display: table;
    overflow: hidden;
  }
}