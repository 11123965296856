@import './mixins';
@import './variable';

%h1 {
  @include media(null, $bp-medium) {
    @include font(30, 400);
    line-height: 37.75px;
  }

  @include font(38, 400);
  font-family: $font-serif;
  line-height: 30px;
  padding-bottom: 16px;
  text-align: center;
  text-transform: capitalize;
}

%h2 {
  @include media(null, $bp-medium) {
    @include font(24, 400);
    line-height: 37.75px;
  }

  @include font(30, 400);
}

%h3 {

  @include media(null, $bp-medium) {
    @include font(22, 400);
    line-height: 37.75px;
  }

  @include font(24, 400);
  font-family: $font-serif;
  line-height: 20px;
}

%h4 {
  @include media(null, $bp-medium) {
    @include font(16, 400);
    font-family: $font-serif;
    line-height: 24px;
  }

  @include font(20, 400);
  font-family: $font-serif;
}

%h5 {
  @include font(16, 400);
  font-family: $font-serif;
  line-height: 16px;
  margin-bottom: 20px;
}


%h6 {
  @include font(14, 400);
  font-family: $font-serif;
  line-height: 12px;
  margin-bottom: 10px;
}