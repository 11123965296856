$whiteBg: #ffffff;
$black-color:rgb(0, 0, 0);
$sidebarBg: #515151;
$purpleTxt: #503291;
$greenTxt: #a5ce43;
$grayTxt: #141c3a;
$commonBorder: #ccc;
$textMute: 1c1c1c;
// Application branding colors 

// Application Branding Theme colors changes these
//------------------------------------------------//
$inputBorder: #4c7ef3;
$textPrimary: #503291;
$textPrimaryLight: #503291;
$theme-blue-dark: #503291;
$brand-purple: #503291;
$brand-primary: #503291;
$errorMsg: #E61E50;
$body-color: #484848;
//-------------------------------------------------//
//images
$logo: url('../img/logo.png') no-repeat;

$font-roboto: 'Roboto';
$base-size: 16;
$base-font-size: 16;
$line-height: 1.3;

$global-font-family: 'Roboto',
sans-serif;


// Breakpoints
$bp-small: 480px;
$bp-xsmall: 566px;
$bp-smedium: 736px;
$bp-medium: 767px;
$bp-xmedium: 990px;
$bp-large: 1024px;
$bp-xxlarge: 1139px;
$bp-xlarge: 1440px;
$bp-maxwidth: 1440px;
$base-size: 16;
$base-font-size: 16;
$line-height: 1.3;
$brand-success: #149B5F !default;
$brand-info: #FFC832 !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;

$brand-magenta: #EB3C96;
$brand-blue: #0F69AF;
$brand-vibrant-green: #A5CD50;
$brand-red: #E61E50;
$brand-yellow: #FFC832;
$brand-green: #149B5F;
$brand-black: #1B1B25;
$brand-grey: #F8F8FC;
$brand-dark-gray: #dadadd;
$brand-white: #FFFFFF;
$tooltip-bg: #5c2d91;
$brand-border: #e9e9ed;

$border-radius: 10px;

$white-color: #fff;
$black-color: rgb(0, 0, 0);
$light-gray-color: #ccc;
$red-border-color: #c00;
$light-red-color: #fff0f0;
$success-color: #0a9e02;
$point-color: #a6bdc4;
$fav-color: #f05c72;
$light-gray-color: #ececec;


//Padding margin
$gutter-1: 1px;
$gutter-2: 2px;
$gutter-5: 5px;
$gutter-6: 6px;
$gutter-7: 7px;
$gutter-8: 8px;
$gutter-10: 10px;
$gutter-12: 12px;
$gutter-15: 15px;
$gutter-16: 16px;
$gutter-17: 17px;
$gutter-20: 20px;
$gutter-22: 22px;
$gutter-24: 24px;
$gutter-25: 25px;
$gutter-30: 30px;
$gutter-32: 32px;
$gutter-35: 35px;
$gutter-40: 40px;
$gutter-44: 44px;
$gutter-50: 50px;
$gutter-60: 60px;
$gutter-100: 100px;

//radius
$radius-5: 5px;


//Colors
// $black-color: #000;
$white-color: #fff;
$light-blue: #03bbd2;
// $red-color: #f00;
$light-gray-color: #ccc;
$red-border-color: #c00;
$light-red-color: #fff0f0;
$success-color: #0a9e02;
$point-color: #a6bdc4;
$fav-color: #f05c72;
$border-color: #c2c2c2;
$feature-point-color: #a48a58;
$arrow-color: #7c7e7d;
$arrow-hover-color: #9cacfd;
$gray-color: #9e9e9e;
$solitude-color: #e6e9ee;
$pager-border-color: #c7c7c7;
$pager-arrow-color: #dcdcdc;
$pager-disable-color: #484848;
$pager-anchor-color: #666;
$pager-current-color: #6f6f6f;
$notify-bg-color: #f6fcff;
$notify-color: #0072b9;

// re-design color theme
$black-color: #1c1c1c;
$cream-color: #fcf9ee;
$stroke-color: #e2e1d7;
$dark-gray-color: #707070;
$red-color: #c00;
$green-color: #0cc241;
// color: #171010;

$font-serif-light: 'MaloneSerif-Book',
Helvetica,
Arial,
sans-serif;
$font-serif: 'MaloneSerif',
Helvetica,
Arial,
sans-serif;
$font-serif-medium: 'MaloneSerif-MediumWeb',
Helvetica,
Arial,
sans-serif;
//Colors RGBA
$white-rgba: rgba($white-color, .25);
$white-rgba-60: rgba($white-color, .6);
$white-rgba-90: rgba($white-color, .9);
$black-rgba-30: rgba($black-color, .3);
$black-rgba-80: rgba($black-color, .8);
$black-rgba-50: rgba($black-color, .1);
$black-rgba-15: rgba($black-color, .15);
$gray-rgba-60: rgba(204, 204, 204, .6);
$green-rgba-80: rgba(10, 158, 2, .08);