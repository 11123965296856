@import '../../../../assets/scss/variable';
@import '../../../../assets/scss/placeholder';

.header {
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .1);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 4;


  @include media (null, $bp-xmedium) {
    display: none;
  }

  &-mobile {
    @include media (null, $bp-xmedium) {
      display: block;
    }

    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 4;
    display: none;

    .hamburger {
      left: -10px;
      background-color: transparent;
      border: 0;
      border-top: 1px solid #1c1c1c;
      cursor: pointer;
      display: inline-block;
      font-size: 0;
      height: 11px;
      line-height: 14px;
      /* margin: 20px 0 23px; */
      min-height: auto;
      outline: none;
      padding: 0;
      position: relative;
      width: 25px;

      &::after {
        border-top: 1px solid $black-color;
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }



  .justify-content-start {
    .nav-item {
      .nav-link {
        @include font(16, 400);
        color: #141c3a;

        &:hover {
          color: #141c3a;
        }

        &:focus {
          box-shadow: 0 0 0 2px #fff, 0 0 0 4px #141c3a;
          border-radius: 2px solid #141c3a;

        }
      }
    }
  }

  .justify-content-end {
    .nav-item {
      .nav-link {
        color: #141c3a;
        @include font(16, 400);
      }
    }
  }
}

.full-width {
  max-width: 100%;
  margin: 0 auto;
  padding-left: 4rem;
  padding-right: 4rem;

  @include media (null, $bp-xmedium) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}